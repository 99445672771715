html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  /* 
  filter: grayscale(100%); */
  font-family: "Nunito", sans-serif;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}


